<template>
  <div @mousedown="close" v-if="value" class="modal" tabindex="-1">
    <div @mousedown.stop class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Find a coach</h5>
          <button @click="close" type="button" class="btn-close"></button>
        </div>
        <div class="modal-body">
          <SearchCoachForm></SearchCoachForm>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FindCoachModal',
  components: {
    SearchCoachForm: () => import('@/components/form/SearchCoachForm'),
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>
